import { create } from 'zustand'

export const useHomeHeroVideo = create<{
  isAutoPlayVideo: boolean
  setIsAutoPlayVideo: (isAutoPlayVideo: boolean) => void
}>((set) => {
  return {
    isAutoPlayVideo: true,
    setIsAutoPlayVideo: (isAutoPlayVideo: boolean) => {
      set({
        isAutoPlayVideo
      })
    }
  }
})
