import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Reload = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M9.5 7V5.5H13.0625L12.2188 4.5C11.1875 3.28125 9.6875 2.5 8 2.5C4.9375 2.5 2.5 4.96875 2.5 8C2.5 11.0625 4.9375 13.5 8 13.5C9.21875 13.5 10.375 13.0938 11.2812 12.4062L12.1875 13.625C11.0312 14.5 9.5625 15 8 15C4.125 15 1 11.875 1 8C1 4.15625 4.125 1 8 1C10.1562 1 12.0938 2 13.375 3.53125L14 4.28125V1H15.5V7H9.5Z" />
    </SvgIcon>
  )
}

export default Reload
