import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import {
  motion,
  useMotionValueEvent,
  useReducedMotion,
  useScroll
} from 'framer-motion'
import { isbot } from 'isbot'
import Container from '~/components/Container'
import Footer from '~/components/Footer'
import Hero from '~/components/Hero'
import IconButton from '~/components/IconButton'
import Reload from '~/components/icons/Reload'
import LinkButton from '~/components/LinkButton'
import MarqueePress from '~/components/Marquees/MarqueePress'
import MouseScroll from '~/components/MouseScroll'
import Nav from '~/components/Nav'
import Typography from '~/components/Typography'
import WillVisibleIn from '~/components/WillVisibleIn'
import { medias } from '~/constants/medias'
import { matchIsServerSideLogged } from '~/firebase.server'
import { replayVideo } from '~/helpers/video'
import { useTrackPageView } from '~/hooks/useTrackPageView'
import { getFixedT } from '~/i18n/i18next.server'
import { useHomeHeroVideo } from '~/stores/HomeHeroVideo'
import { cn } from '~/utils/cn'
import { buildMetas } from '~/utils/metas'
import { trackEvent } from '~/utils/tracking'
import { useIsomorphicLayoutEffect } from '@zoi/react-hooks'
import BookASlot from './BookASlot'
import HomeCheckup from './HomeCheckup'
import HomeFollowUp from './HomeFollowup'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isBot = isbot(request.headers.get('user-agent'))

  const { t } = await getFixedT(request)

  const isLogged = await matchIsServerSideLogged(request)

  return { isBot, title: t('common:metas.title.default'), isLogged }
}

export const meta: MetaFunction<typeof loader> = ({
  location,
  params,
  data
}) => {
  return buildMetas(
    {
      location,
      params,
      title: data?.title
    },
    [
      {
        rel: 'prefetch',
        as: 'video',
        href: '/video/hero.mp4'
      }
    ]
  )
}

export const handle = {
  i18n: ['home', 'booking']
}

const HomeRoute = () => {
  const { isBot, isLogged } = useLoaderData<typeof loader>()
  const contentAfterHero = React.useRef<HTMLDivElement>(null)
  const { scrollY } = useScroll()
  const { isAutoPlayVideo, setIsAutoPlayVideo } = useHomeHeroVideo()
  const heroVideoRef = React.useRef<HTMLVideoElement>(null)
  const [isNavScrolled, setIsNavScrolled] = React.useState(false)
  const isReducedMotion = useReducedMotion()
  const [isVideoEnded, setIsVideoEnded] = React.useState(
    !isAutoPlayVideo || isBot
  )
  const { t } = useTranslation(['home', 'booking'])

  useTrackPageView('Home')

  useMotionValueEvent(scrollY, 'change', (currentScrollY) => {
    if (isReducedMotion) {
      return
    }

    setIsNavScrolled(currentScrollY > 20)
  })

  useIsomorphicLayoutEffect(() => {
    if (isAutoPlayVideo && heroVideoRef.current) {
      try {
        heroVideoRef.current.play()
      } catch {
        setIsVideoEnded(true)
        setIsAutoPlayVideo(false)
      }
    }
  }, [isAutoPlayVideo, heroVideoRef.current])

  const scrollToAboveTheFold = () => {
    contentAfterHero.current?.scrollIntoView({
      behavior: isReducedMotion ? 'instant' : 'smooth',
      block: 'start'
    })
  }

  const handleReplayVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    trackEvent('REPLAY_HOME_VIDEO')

    if (heroVideoRef.current) {
      setIsVideoEnded(false)
      replayVideo(heroVideoRef.current)
    }
  }

  const handleVideoEnded = () => {
    setIsVideoEnded(true)
    setIsAutoPlayVideo(false)
    trackEvent('HOME_VIDEO_ENDS')
  }

  const handleVideoAborted = () => {
    setIsVideoEnded(true)
    setIsAutoPlayVideo(false)
    trackEvent('HOME_VIDEO_ABORTED')
  }

  const handleVideoError = () => {
    setIsVideoEnded(true)
    setIsAutoPlayVideo(false)
    trackEvent('HOME_VIDEO_ERROR')
  }

  const skipVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    trackEvent('SKIP_HOME_VIDEO')
    setIsVideoEnded(true)
    setIsAutoPlayVideo(false)
  }

  return (
    <>
      <Nav
        className={cn('!fixed transition-all', {
          'opacity-0': !isVideoEnded,
          '!opacity-100': isNavScrolled
        })}
      />
      <main>
        <Hero
          videoProps={{
            children: (
              <>
                <source
                  src={medias.video.heroVertical}
                  type="video/mp4"
                  media="(orientation: portrait)"
                />
                <source src={medias.video.hero} type="video/mp4" />
              </>
            ),
            src: medias.video.hero,
            autoPlay: isAutoPlayVideo,
            // React sucks for data html attributes
            // @ts-expect-error: react doesn't type correctly the data attribute
            'data-video-ended': isVideoEnded,
            id: 'home-hero-video',
            onEnded: handleVideoEnded,
            onAbort: handleVideoAborted,
            onError: handleVideoError,
            ref: heroVideoRef
          }}
          aria-labelledby="hero-title"
          isContentVisible={isVideoEnded}
        >
          {!isVideoEnded ? (
            <motion.button
              className="absolute bottom-5 right-5 px-3 py-2 text-white"
              type="button"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              onClick={skipVideo}
            >
              {t('home:skip')}
            </motion.button>
          ) : null}
          {isVideoEnded ? (
            <div className="flex h-full w-full flex-col items-center justify-between overflow-hidden pt-[var(--nav-height)]">
              <IconButton
                size="sm"
                color="transparent"
                className="absolute bottom-5 right-5 max-md:hidden"
                onClick={handleReplayVideo}
                aria-label={t('home:replayVideo')}
              >
                <Reload width={13} height={13} className="text-black/25" />
              </IconButton>
              <div />
              <div className="flex flex-col items-center gap-y-10">
                <Container className="flex flex-col items-center gap-y-10">
                  <Typography
                    variant="h1"
                    className="text-center text-8xl font-normal uppercase max-lg:text-7xl max-md:text-6xl max-sm:text-4xl"
                    family="brand"
                    id="hero-title"
                  >
                    <motion.span
                      className="block"
                      initial={{ opacity: 0, scale: 1.2 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    >
                      <Trans i18nKey="home:headline" />
                    </motion.span>
                  </Typography>
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <LinkButton rounded="full" size="lg" to="#book-a-slot">
                      {t('common:book')}
                    </LinkButton>
                  </motion.div>
                </Container>
                <WillVisibleIn inMs={300}>
                  <MarqueePress className="mx-auto mt-8 max-w-screen-2xl" />
                </WillVisibleIn>
              </div>
              <div className="pb-8">
                <button
                  type="button"
                  onClick={scrollToAboveTheFold}
                  aria-label={t('common:seeNextContent')}
                  className="flex cursor-pointer flex-col items-center gap-y-2 p-2"
                >
                  <MouseScroll className="text-black/10" />
                </button>
              </div>
            </div>
          ) : null}
        </Hero>
        <HomeCheckup ref={contentAfterHero} />
        <HomeFollowUp />
        <BookASlot isLogged={isLogged} />
      </main>
      <Footer />
    </>
  )
}

export default HomeRoute
