import React from 'react'
import Marquee, { type MarqueeProps } from '~/components/Marquee'
import { trackEvent } from '~/utils/tracking'
import * as logos from './logos'

const PRESS_ARTICLES = [
  {
    title: 'BFM Business',
    logo: logos.LogoBfmBusiness,
    url: 'https://www.youtube.com/watch?v=xWf7VJb_fFk',
    width: 42,
    height: 42
  },
  {
    title: 'BFM TV',
    logo: logos.LogoBfmTv,
    url: 'https://www.bfmtv.com/economie/replay-emissions/good-morning-business/l-integrale-de-good-morning-business-du-lundi-13-novembre_EN-202311130301.html',
    width: 32,
    height: 32
  },
  {
    title: 'Challenges',
    logo: logos.LogoChallenges,
    url: 'https://assets-global.website-files.com/659e5e89f44b878749af6159/65d703f2c1f2bd297c9839e9_Challenge%20ZOI%20ouvre%20son%20centre%20de%20sante%CC%81%201910%202223.pdf',
    width: 93,
    height: 23
  },
  {
    title: 'La Tribune',
    logo: logos.LogoLaTribune,
    url: 'https://assets-global.website-files.com/659e5e89f44b878749af6159/65d703f34d8d7c1869cca699_La%20Tribune%20Dimanche%20La%20startup%20Zoi%CC%88%20parie%20sur%20la%20me%CC%81decine%20pre%CC%81ventive%203.pdf',
    width: 93,
    height: 35
  },
  {
    title: 'Le Figaro',
    logo: logos.LogoLeFigaro,
    url: 'https://assets-global.website-files.com/659e5e89f44b878749af6159/65d703f277dc178f3f1ab171_le%20figaro%20pdf.pdf',
    width: 120,
    height: 16
  },
  {
    title: 'Maddyness',
    logo: logos.LogoMaddyness,
    url: 'https://assets-global.website-files.com/659e5e89f44b878749af6159/65d703f389a67c36cb4dc224_Maddyness%20pdf.pdf',
    width: 96,
    height: 19
  },
  {
    title: 'Radio Classique',
    logo: logos.LogoRadioClassique,
    url: 'https://player.fm/series/les-voix-de-leconomie/ismael-emelien-ancien-conseiller-special-demmanuel-macron-a-lelysee-cofondateur-de-zoi',
    width: 40,
    height: 40
  },
  {
    title: 'Les Echos',
    logo: logos.LogoLesEchos,
    url: 'https://www.lesechos.fr/weekend/business-story/sante-zoi-cette-start-up-qui-aide-a-vivre-mieux-plus-longtemps-2077441',
    width: 86,
    height: 21
  }
] as const satisfies {
  title: string
  logo: string
  width: number
  height: number
  url: string
}[]

const PressArticles = () => {
  return (
    <ul className="flex items-center justify-center py-4">
      {PRESS_ARTICLES.map(({ title, logo, url, width, height }) => {
        return (
          <li key={title} className="mr-12">
            <a
              className="block px-4 opacity-25 transition-opacity hover:opacity-100"
              href={url}
              title={title}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent('PRESS_CLICK', {
                  company: title
                })
              }}
            >
              <img
                src={logo}
                alt={`Logo ${title}`}
                loading="lazy"
                width={width}
                height={height}
              />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

const MarqueePress = (props: MarqueeProps) => {
  return (
    <Marquee isPausedOnHover gradientColor="white" {...props}>
      <PressArticles />
    </Marquee>
  )
}

export default MarqueePress
