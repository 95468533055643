import React from 'react'
import { motion } from 'framer-motion'
import { cn } from '~/utils/cn'

const MouseScroll = ({ className }: PropsWithClassName) => {
  return (
    <div
      className={cn(
        'relative h-[36px] w-[20px] rounded-full border-2 border-current bg-transparent py-2',
        className
      )}
    >
      <div className="relative h-full w-full">
        <motion.span
          className="absolute left-1/2 -ml-[2px] block aspect-square h-[4px] rounded-full bg-current"
          initial={{ top: 3, opacity: 0 }}
          animate={{
            top: [3, 0, 13],
            opacity: [0, 1, 1, 0]
          }}
          transition={{
            repeat: Infinity,
            times: [0, 0.3, 0.8, 1],
            repeatDelay: 1,
            duration: 2,
            ease: 'easeInOut'
          }}
        />
      </div>
    </div>
  )
}

export default MouseScroll
