import React from 'react'
import { motion, type Variants } from 'framer-motion'
import { useTimeout } from '@zoi/react-hooks'

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
} satisfies Variants

export type WillVisibleInProps = {
  inMs: number
  children: React.ReactNode
}

const WillVisibleIn = ({ inMs, children }: WillVisibleInProps) => {
  const [isVisible, setIsVisible] = React.useState(false)

  useTimeout(() => {
    setIsVisible(true)
  }, inMs)

  return (
    <motion.div
      initial="hidden"
      animate={isVisible ? 'visible' : 'hidden'}
      variants={fadeInVariants}
    >
      {children}
    </motion.div>
  )
}

export default WillVisibleIn
